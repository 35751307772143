@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: "mr-eaves-xl-sans", sans-serif;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	background-image: url('../../dist/img/bg-new.png');
	background-size: cover;
	background-position: center;
	min-height: 100vh;
	background-repeat: no-repeat;
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	header,main,footer{
		position: relative;
	}
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

header {
	padding: 35px 0 0;
	text-align: center;
	svg{
		display: inline-block;
		width: 100%;
		max-width: 550px;
		margin-bottom: 20px;
	}
	.menu{
		@include flex(row,center,center);
		row-gap: 5px;
		column-gap: 30px;
		text-transform: uppercase;
		a{
			font-size: 16px;
			letter-spacing: 8px;
			@include hover-focus{
				color: $gray;
			}
			.space{
				width: 8px;
				display: inline-block;
			}
		}
		span{
			@include tablet-down{
				padding-top: 8px;
				@include flex(row,center,center);
				width: 100%;
				gap: 30px;
			}
		}
	}
	@include tablet-up{
		.menu{
			gap: 30px;
			a{
				font-size: 20px;
			}
			span{
				@include flex(row,initial,initial);
				gap: 30px;
			}
		}
	}
}

main {
	min-height: calc(100vh - 240px);
	@include flex(column,center,center);
	.container{
		padding: 40px;
		img{
			width: 100%;
			max-width: 570px;
			margin: 0 auto;
			max-height: 60vh;
			object-fit: contain;
			opacity: 0;
			pointer-events: none;
		}
	}
	@include tablet-up{
		min-height: calc(100vh - 210px);
	}
	.popup{
		background-color: #000000de;
		position: fixed;
		width: 100%;
		height: 100vh;
		display: block;
		opacity: 0;
		transition: all .4s;
		pointer-events: none;
		&.active{
			opacity: 1;
			pointer-events: all;
		}
		.container{
			height: 100vh;
			@include flex(column,center,center);
			.wrapper{
				>div{
					max-height: 80vh;
					overflow-y: scroll;
				}
				background-color: $black;
				border: solid 1px #333;
				width: 100%;
				max-width: 800px;
				padding: 20px;
				.close{
					background-color: $white;
					color: $black;
					border: none;
					border-radius: 0;
					width: 40px;
					height: 40px;
					padding: 0;
					@include flex(column,center,center);
					font-size: 20px;
					position: absolute;
					top: 0;
					right: 0;
				}
				.seated-events-table{
					border: none;
				}
			}
		}
		@include tablet-up{
			.container{
				.wrapper{
					padding: 40px;
				}
			}
		}
	}

}

footer {
	padding:  0 0 20px 0;
	.social{
		@include flex(row,center,center);
		gap: 20px;
		margin-bottom: 35px;
		a{
			width: 25px;
			height: 25px;
			border-radius: 25px;
			background-color: $white;
			color: $black;
			@include flex(column,center,center);
			font-size: 15px;
		}
	}
	.copyright{
		p{
			text-align: center;
			font-size: 10px;
			color: $gray;
			margin: 0;
			line-height: 1.2;
			a{
				color: $gray;
				@include hover-focus{
					color: $white;
				}
			}
		}
	}
}